import React, { useState, useEffect } from "react";
import { Icon, Menu, Sidebar, Button } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import {useTranslation} from 'react-i18next';
import packageJson from '../../../package.json';
import "./main-layout.css";
import { useContext } from "react";
import { Context as KmartContext } from "../../context/kmartcontext";
import { Context as AppContext } from "../../context/appSettings";
import { useQuery } from "@apollo/react-hooks";
import { WHO_AM_I } from "../../components/GraphQL";

const CustomMenu = ({ to, children, setVisible }) => {
  return (
    <Menu.Item
      as={NavLink}
      to={to}
      exact
      onClick={() => {
        setVisible(false);
      }}
    >
      {children}
    </Menu.Item>
  );
};

const VerticalSidebar = (props) => {
  const {isInCollection} = useContext(AppContext);

  const { animation, visible, isMobile, signOut } = props;
  const {t, i18n} = useTranslation('menu');
  

  return (
    <Sidebar
      className="sidebar"
      as={Menu}
      animation={animation}
      vertical
      direction={i18n.dir() === 'rtl' ? 'right' : 'left'}
      visible={visible}
      width={isMobile ? "wide" : null}
    >
      <CustomMenu to="/" {...props}>
        <Icon name="dashboard" />
        {t('orders')}
      </CustomMenu>
      <CustomMenu to="/kmart/items"  {...props}>
        <Icon name="ordered list" />
        {t('products')}
      </CustomMenu>
      {isInCollection && <CustomMenu to="/RequestDriverv2"  {...props}>
        <Icon name="car" />
        {t('requestDriver')}
      </CustomMenu>}

      <Button fluid id="sign-out-button" icon="sign-out" content={t("menu:signOut")} primary onClick={signOut} />
      <span id="version-num">v{packageJson.version}</span>
    </Sidebar>
  );
};

const KmartLayout = ({ children, signOut }) => {
  const context = useContext(KmartContext);

  const [visible, setVisible] = React.useState(false);
  const [store, setStore] = useState(null);
  const {t} = useTranslation('menu');
  useQuery(WHO_AM_I, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if(data && data.whoAmI) {
        setStore(data.whoAmI);
      }
    },
    onError: (err) => {console.log("Store error", err)}
  });
  const closeSideMenu = () => {
    setVisible(!visible)
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
        return React.cloneElement(child, { isSideMenuOpen: visible , closeSideMenu: closeSideMenu });
    }
    return child;
  });


  return (
    <div className="main">
      <nav id="top-nav">
        <Button icon id="menu-hamburger" onClick={() => setVisible(!visible)}>
          <Icon name="bars" />
        </Button>
        <div style={{ margin: 3, fontSize: 18, fontWeight: 600, paddingInlineStart: 12 }}>{store?.shopName || store?.name}</div>
      </nav>
      <Sidebar.Pushable>
        <VerticalSidebar visible={visible} animation="overlay" setVisible={setVisible} signOut={signOut} />
        <Sidebar.Pusher className="main-content">
          <div className="content">{childrenWithProps}</div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default KmartLayout;
