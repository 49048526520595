import React, { useContext, useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/react-hooks";
import * as firebase from "firebase";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import {
  Accordion,
  Button,
  Checkbox,
  Dimmer,
  Icon,
  Loader,
  Pagination,
  Popup,
  Table,
} from "semantic-ui-react";
import moment from "moment";

import { useTranslation } from "react-i18next";
import {
  CANCEL_TASK,
  CREATE_A_TASK,
  EDIT_A_TASK,
  GET_A_TASK,
  GET_DRIVER_REQUESTS,
  UPDATE_TASK,
  WHO_AM_I,
} from "../../components/GraphQL";
import RequestForm from "./RequestDriverForm";
import AddressTravelDetails from "./requestDetails";
import INITIAL_REQUEST from "../../constants/driverRequest";
import { Context as AppContext } from "../../context/appSettings";
import { getCellTheme, getStatusMappedCell } from "../../utils/table";
import SortableHeaderCell from "../../components/Table/sortableHeaderCell";
import { DebounceInput } from "react-debounce-input";
import { calculateTravelDistance, convertSeconds } from "../../utils/helpers";

const INITIAL_PAGE_SORT_FILTER_VALUE = {
  page: 1,
  totalPages: 1,
  limit: 10,
  searchFilter: {},
  date: new Date(),
  sortColumn: {
    column: "_id",
    direction: -1,
  },
};

const inactiveStates = [
  "cancelled",
  "declined",
  "delivered",
  "completed",
  "rejected",
];

const getPickupLocation = (request) => {
  return request?.pickupLocation?.fullAddress?.length > 0
    ? request?.pickupLocation?.fullAddress.join(", ")
    : request?.addressDetails?.fromAddr.address;
};

const getDeliveryLocation = (request) => {
  return request?.deliveryLocation?.fullAddress?.length > 0
    ? request?.deliveryLocation?.fullAddress.join(", ")
    : request?.addressDetails?.toAddr.address || request.deliveryZone;
};

const RequestDriverV2 = () => {
  const { isInCollection } = useContext(AppContext);
  const { t } = useTranslation("requestDriver");
  const [createTask] = useMutation(CREATE_A_TASK);
  const [cancelTask] = useMutation(CANCEL_TASK);
  const [editATask] = useMutation(EDIT_A_TASK);

  const currentUser = firebase.auth().currentUser;
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);
  const [requests, setRequests] = useState([]);
  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const [open, setOpen] = useState(false);

  const { refetch: fetchCancelled } = useQuery(GET_A_TASK, {
    variables: { id: null },
    skip: true,
  });

  const [request, setRequest] = useState({
    ...INITIAL_REQUEST,
    sellerId: currentUser ? currentUser.uid : "",
    sellerName: currentUser ? currentUser.displayName : "",
  });

  const [
    { page, totalPages, date, limit, searchFilter, sortColumn },
    setPageSortFilterLimit,
  ] = useState(INITIAL_PAGE_SORT_FILTER_VALUE);

  useSubscription(UPDATE_TASK, {
    variables: {
      sellerId: currentUser.uid,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const newTask = subscriptionData.data.updateTask;
      onTaskUpdated(newTask);
    },
  });

  useEffect(() => {
    getDriverRequests({
      variables: {
        filter: {
          sellerId: currentUser.uid,
          ...(showOnlyActive ? { status: { $nin: inactiveStates } } : {}),
          ...searchFilter,
        },
        limit,
        page,
        sortJson: {
          [sortColumn.column]: sortColumn.direction === "ascending" ? 1 : -1,
        },
      },
    });
  }, [currentUser.uid, date, searchFilter, page, sortColumn, showOnlyActive]);

  const [getDriverRequests, { loading }] = useLazyQuery(GET_DRIVER_REQUESTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setRequests(data.getTasks.tasks);
      setPageSortFilterLimit((prevState) => {
        return {
          ...prevState,
          limit: data.getTasks.limit,
          totalPages: Math.ceil(data.getTasks.total / data.getTasks.limit),
        };
      });
    },
    onError: (err) => {
      console.log("Store error", err);
    },
  });

  const {data: selfData, loading: selfloading} = useQuery(WHO_AM_I);

  useEffect(() => {
    console.log(selfData)
    if(selfData?.whoAmI?.location) {
      setRequest({
        ...request,
        pickupLocation: {
          latitude: selfData.whoAmI.location.latitude,
          longitude: selfData.whoAmI.location.longitude,
          fullAddress: ''
        }
      })
    }
  }, [selfData])


  const exportTableToExcel = () => {
    const flattenedRequests = requests.map((request) => ({
      "Order Number": request.orderNumber,
      "Customer Name": request.customerName,
      "Customer Number": request.customerNumber,
      "Alternate Customer Number": request.alternateCustomerNumber,
      "Cash to be collected": request.totalAmount,
      "Collect Cash": request.collectCash,
      "Additional notes": request.specialNotes,
      Status: request.status,
      "Driver Name": request?.driver?.name,
      "Driver Number": request?.driver?.number,
      "Pickup Location": getPickupLocation(request),
      "Pickup ltd/lng": `${request?.pickupLocation?.latitude}, ${request?.pickupLocation?.longitude}`,
      "Delivery Location": getDeliveryLocation(request),
      "Delivery ltd/lng": `${request?.deliveryLocation?.latitude}, ${request?.deliveryLocation?.longitude}`,
      "Total Distance": request?.travelDetail
        ? `${calculateTravelDistance(request.travelDetail.totalDistance)} km`
        : request?.tookanwebhookrequest?.total_distance,
      "Estimated Time to deliver": request?.travelDetail
        ? convertSeconds(request.travelDetail.duration)
        : "N/A",
      "Estimated Price": request?.estimatedPrice || ' - ',
      "Completed At": request?.completedTime,
      "Created At": moment(1 * request.createdAt).format("lll"),
    }));
    const ws = XLSX.utils.json_to_sheet(flattenedRequests);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "DriverRequests");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, "DriverRequests.xlsx");
  };

  const handlePaginationChange = (e, { activePage }) => {
    setPageSortFilterLimit((prevState) => {
      return {
        ...prevState,
        page: activePage,
      };
    });
  };

  const handleSearchFilter = (key, value) => {
    let newSearchFilter = { ...searchFilter };

    if (key === "searchText") {
      newSearchFilter = {};
    } else {
      delete newSearchFilter["searchText"];
    }

    if (value) {
      newSearchFilter[key] = value;
    } else {
      delete newSearchFilter[key];
    }

    setPageSortFilterLimit((prevState) => {
      return {
        ...prevState,
        page: 1,
        searchFilter: newSearchFilter,
      };
    });
  };

  const onTaskUpdated = (newTask) => {
    setRequests((prevRequests) => {
      const updatedRequests = prevRequests.map((request) => {
        if (request.id === newTask.id) {
          return { ...request, ...newTask };
        }
        return request;
      });

      return updatedRequests;
    });
  };

  const onCancelTask = async (id) => {
    setCancelButtonDisabled(true);
    try {
      await cancelTask({
        variables: {
          id,
        },
      });
      const { data } = await fetchCancelled({ id });
      if (data && data.getTask) {
        const cancelledTask = data.getTask;
        const updatedRequests = requests.map((request) => {
          if (request.id === cancelledTask.id) {
            return cancelledTask;
          }
          return request;
        });

        setRequests(updatedRequests);
      }
    } catch (error) {
      window.alert("failed to cancel");
    }
    setCancelButtonDisabled(false);
  };

  const cleanup = () => {
    setRequest({
      ...request,
      ...INITIAL_REQUEST,
    });
    if(selfData?.whoAmI?.location) {
      setTimeout(()=> {
        setRequest({
          ...request,
          pickupLocation: {
            latitude: selfData.whoAmI.location.latitude,
            longitude: selfData.whoAmI.location.longitude,
            fullAddress: ''
          }
        })
      }, 500)
    }
  };

  const onCancel = () => {
    cleanup();
    setOpen(false);
  };

  const initializePageSortFilter = (cachePrevState) => {
    setPageSortFilterLimit((prevState) => {
      const state = cachePrevState ? prevState : INITIAL_PAGE_SORT_FILTER_VALUE;
      return {
        ...state,
        date: new Date(),
      };
    });
  };

  const onSend = async (newValue) => {
    const { zone, area } = newValue.delivery;
    const finalRequest = {
      customerName: newValue.customerName,
      customerNumber: newValue.customerNumber,
      alternateCustomerNumber: newValue.alternateCustomerNumber,
      deliveryZone: `${zone}${zone && area ? " - " : ""}${area}`,
      additionalAddressDetail: newValue.additionalAddressDetail,
      collectCash: newValue.collectCash,
      totalAmount: newValue.totalAmount,
      specialNotes: newValue.specialNotes,
      customPickup: newValue.customPickup,
      pickupLocation: newValue.pickupLocation,
      deliveryLocation: newValue.deliveryLocation,
      pickupName: newValue.pickupNumber,
      pickupNumber: newValue.pickupNumber,
      pickupNotes: newValue.pickupNotes,
      sellerId: currentUser ? currentUser.uid : "",
      sellerName: currentUser ? currentUser.displayName : "",
    };
    setSendButtonDisabled(true);
    if (!!request.id) {
      try {
        await editATask({
          variables: {
            updatedTask: finalRequest,
            id: request.id,
          },
        });
        initializePageSortFilter(false);
        cleanup();
      } catch (error) {
        console.log(error);
        window.alert(
          "Failed to edit. Check the fields and your internet connection.",
        );
      }
    } else {
      try {
        await createTask({
          variables: {
            taskInput: finalRequest,
          },
        });
        initializePageSortFilter(false);
        cleanup();
      } catch (error) {
        console.log(error);
        window.alert(
          "Failed to send your request. Check the fields and your internet connection.",
        );
      }
    }
    cleanup();
    setOpen(false);
    setSendButtonDisabled(false);
  };

  const RequestRow = ({ request }) => {
    const [expandedRowId, setExpandedRowId] = useState(null);

    const handleRowClick = (id) => {
      setExpandedRowId(expandedRowId === id ? null : id);
    };

    return (
      <>
        <Table.Row
          onClick={() => handleRowClick(request.id)}
          key={request.id}
          className={`cursor-pointer table-row-hover`}
        >
          <Table.Cell
            style={{
              borderLeft: `5px solid ${getCellTheme(request.status)}`,
              paddingLeft: "0",
            }}
          >
            <Icon name={expandedRowId ? "chevron down" : "chevron right"} />

            {request.orderNumber}
          </Table.Cell>
          <Table.Cell>{request.customerName}</Table.Cell>
          <Table.Cell className={"break-word"}>
            {request.customerNumber}
            {request.alternateCustomerNumber ? (
              <span>,&nbsp;{request.alternateCustomerNumber}</span>
            ) : (
              ""
            )}
          </Table.Cell>
          {/* <Table.Cell>{request.deliveryZone}</Table.Cell> */}
          <Table.Cell>{request.specialNotes}</Table.Cell>
          <Table.Cell> {getPickupLocation(request)}</Table.Cell>
          <Table.Cell>{getDeliveryLocation(request)}</Table.Cell>
          <Table.Cell textAlign={"right"}>
            {request.totalAmount > 0 ? `${request.totalAmount} KD` : "-"} &nbsp;
            {/*<span title="Collect the Cash">*/}
            {/*  {request.collectCash && <Icon name={"dollar"} color={"green"} />}*/}
            {/*</span>*/}
          </Table.Cell>
          <Table.Cell>{moment(1 * request.createdAt).format("lll")}</Table.Cell>
          <Table.Cell>{getStatusMappedCell(request.status)}</Table.Cell>
          <Table.Cell>
            {request.status === "pending" && (
              <Button
                onClick={() => onCancelTask(request.id)}
                disabled={cancelButtonDisabled}
              >
                {t("requestDriver:cancel")}
              </Button>
            )}
          </Table.Cell>
        </Table.Row>
        {expandedRowId === request.id && request?.statusHistory && (
          <Table.Row
            style={{
              borderTopColor: "grey",
              borderTopWidth: 0.5,
              backgroundColor: "#f7f7f7",
            }}
          >
            <Table.Cell colSpan="12">
              <Accordion fluid>{AddressTravelDetails(request)}</Accordion>
            </Table.Cell>
          </Table.Row>
        )}
      </>
    );
  };

  const handleSort = (e, val) => {
    setPageSortFilterLimit((prevState) => {
      return {
        ...prevState,
        sortColumn: {
          column: val,
          direction:
            prevState.sortColumn.direction === "ascending"
              ? "descending"
              : "ascending",
        },
      };
    });
  };

  return isInCollection ? (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 20,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            className="ui button positive"
            disabled={selfloading}
            style={{ width: "fit-content" }}
            onClick={() => setOpen(true)}
          >
            {t("requestDriver:RequestADriver")}
          </Button>
          <Popup
            content="Request a driver on demand for call deliveries. When you receive orders via phone calls instead of in-app orders, you can request a driver to handle the delivery efficiently."
            trigger={
              <div>
                <Icon
                  name="info circle"
                  style={{ marginLeft: "0.5em", color: "green" }}
                />
              </div>
            }
          />
        </div>
        <div style={{ display: "flex" }}>
          <Checkbox
            label={`${t("requestDriver:ShowOnlyActive")}`}
            toggle
            checked={showOnlyActive}
            name="customPickup"
            onChange={(e) => {
              setShowOnlyActive(!showOnlyActive);
            }}
            style={{ margin: 10 }}
          />
          <Button className="ui button blue" onClick={exportTableToExcel}>
            Export to Excel
          </Button>
          <DebounceInput
            placeholder="Search Requests"
            minLength={2}
            type="text"
            width="150px"
            debounceTimeout={1000}
            className="filter-input"
            value={searchFilter["searchText"] || ""}
            style={{ marginRight: 12 }}
            onChange={(e) => handleSearchFilter("searchText", e.target.value)}
          />

          <Button
            className="ui button yellow"
            onClick={() => initializePageSortFilter(false)}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon name="refresh" />
              <p style={{ marginInlineStart: 10 }}>
                {t("requestDriver:Refresh")}
              </p>
            </div>
          </Button>
        </div>
      </div>
      <Table celled sortable striped fixed>
        <Table.Header>
          <Table.Row>
            <SortableHeaderCell
              label={t("requestDriver:OrderNumber")}
              column="orderNumber"
              sortColumn={sortColumn}
              searchFilterValue={searchFilter["orderNumber"] || ""}
              handleSort={handleSort}
              width={"2"}
            />
            <SortableHeaderCell
              label={t("requestDriver:CustomerName")}
              column="customerName"
              width={"3"}
              sortColumn={sortColumn}
              searchFilterValue={searchFilter["customerName"] || ""}
              handleSort={handleSort}
              handelSearch={(e) =>
                handleSearchFilter("customerName", e.target.value)
              }
            ></SortableHeaderCell>
            <SortableHeaderCell
              label={t("requestDriver:CustomerNumber")}
              column="customerNumber"
              sortColumn={sortColumn}
              width={"3"}
              searchFilterValue={searchFilter["customerNumber"] || ""}
              handleSort={handleSort}
              handelSearch={(e) =>
                handleSearchFilter("customerNumber", e.target.value)
              }
            />
            {/* <SortableHeaderCell
              label={t("requestDriver:Zone")}
              column="deliveryZone"
              sortColumn={sortColumn}
              handleSort={handleSort}
              width={"3"}
              searchFilterValue={searchFilter["deliveryZone"] || ""}
              handelSearch={(e) =>
                handleSearchFilter("deliveryZone", e.target.value)
              }
            /> */}
            <Table.HeaderCell width={"3"}>
              {t("requestDriver:specialNotes")}
            </Table.HeaderCell>
            <Table.HeaderCell width={"3"}>
              {t("requestDriver:From")}
            </Table.HeaderCell>
            <Table.HeaderCell width={"3"}>
              {t("requestDriver:To")}
            </Table.HeaderCell>

            <SortableHeaderCell
              label={t("requestDriver:cashToBeCollected")}
              breakLabel={true}
              column="totalAmount"
              width={"2"}
              sortColumn={sortColumn}
              searchFilterValue={searchFilter["totalAmount"] || ""}
              handleSort={handleSort}
            />

            <Table.HeaderCell width={"3"}>
              {t("requestDriver:createdAt")}
            </Table.HeaderCell>

            <SortableHeaderCell
              label={t("requestDriver:Status")}
              column="status"
              sortColumn={sortColumn}
              width={"3"}
              searchFilterValue={searchFilter["status"] || ""}
              handleSort={handleSort}
              handelSearch={(e) => handleSearchFilter("status", e.target.value)}
            />
            <Table.HeaderCell width={"2"}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {loading && (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          )}
          {requests.map((request, index) => (
            <RequestRow request={request} key={request.id + index} />
          ))}
        </Table.Body>
      </Table>
      <Pagination
        activePage={page}
        boundaryRange={3}
        onPageChange={handlePaginationChange}
        size="mini"
        siblingRange={3}
        totalPages={totalPages}
      />
      <RequestForm
        open={open}
        onSubmit={onSend}
        initialValue={request}
        sendButtonDisabled={sendButtonDisabled}
        onCancel={onCancel}
      />
    </div>
  ) : (
    <div>Unauthorized</div>
  );
};

export default RequestDriverV2;
